.milestones {
    text-align: center;
    padding: 40px 20px;
  }
  
  .milestone-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .milestone {
    background-color: $white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    text-align: center;
  }
  
  .milestone:hover {
    transform: translateY(-5px);
  }
  
  .milestone-icon {
    font-size: 40px;
    margin-bottom: 10px;
  }
  
  .milestone h3 {
    font-size: 18px;
    margin: 10px 0;
  }
  
  .milestone h4 {
    font-size: 16px;
    font-weight: bold;
    margin: 5px 0;
  }
  
  .milestone p {
    font-size: 14px;
    color: #666;
  }