.resume-container {
    max-width: 840px;
    margin: 40px auto;
    padding: 40px;
    background: #161b22;
    color: #c9d1d9;
    font-family: "Inter", Arial, sans-serif;
    border-radius: 10px;
}

.resume-header {
    text-align: left;
    margin-bottom: 18px;
}

.resume-header h1 {
    font-size: 32px;
    font-weight: 700;
    color: white;
    text-align: center;
}

.resume-header {
    font-size: 20px;
    color: #8b949e;
}

.resume-summary {
    font-size: 14px;
    color: #8b949e;
}

.resume-contact {
    font-size: 14px;
    color: #8b949e;
    margin-bottom: 20px;
}

.resume-contact a {
    color: #58a6ff;
    text-decoration: none;
}

.resume-contact a:hover {
    text-decoration: underline;
}

.resume-section {
    margin-bottom: 24px;
}

.resume-section h2 {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: #8b949e;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.resume-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.resume-experience h3,
.resume-project h3 {
    font-size: 18px;
    font-weight: 600;
    color: white;
    margin-bottom: 0px;
}

.resume-experience span {
    font-size: 14px;
    font-style: italic;
    // color: #8b949e;
    // margin-bottom: 5px;
}

.resume-experience p,
.resume-project p {
    font-size: 14px;
    color: #c9d1d9;
}

.resume-tech {
    font-size: 14px;
    color: #8b949e;
    // font-style: italic;
    margin-bottom: 10px;
}

.resume-date-location {
    font-size: 13px;
    color: #8b949e;
    margin-bottom: 10px;
    text-align: right;
}

ul {
    list-style: none;
    padding: 0;
}

ul li {
    font-size: 14px;
    color: #c9d1d9;
    margin-bottom: 5px;
}

.resume-skills {
    margin-top: 10px;
    padding: 0;
}

.resume-skills li {
    font-size: 14px;
    color: #c9d1d9;
    margin-bottom: 5px;
}

.resume-skills strong {
    color: white;
}

.resume-education-title,
.resume-education-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.resume-education-title {
    font-size: 16px;
}

.resume-education-details span {
    font-size: 14px;
    font-style: italic;
}

.download-resume {
    display: flex;
    justify-content: center;
    // margin-top: 16px;
    // border: 0.5px solid #58a6ff;
}