/* Button */
.c-button {
  position: relative;
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-weight: 700;
  border-radius: 16px;
  white-space: nowrap;
  border: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  transition: all .25s ease;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
  will-change: transform;

  &:active {
    transform: translateY(2px) scale(0.98);
  }

  &--primary {
    color: $white;
    background: linear-gradient(90deg, $primary-color 0%, $secondary-color 167%);

    &:hover {
      color: $white !important;

      &:before {
        opacity: 1;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 100%;
      opacity: 0;
      will-change: transform;
      transition: opacity 0.35s;
      background: linear-gradient(90deg, $primary-color 0%, $secondary-color 107%);
    }
  }

  &--secondary {
    color: $text-color;
    background: $background-color-alt;

    &:hover {
      color: $text-color;
    }
  }

  &--small {
    padding: 16px 32px;
    font-size: 16px;
  }

  &--large {
    padding: 24px 44px;
    font-size: 18px;
  }
}