/* Color Scheme */
$primary-color: #9893DA;
$secondary-color: #9893DA;
$white: #1A1A1A;
$dark: #FFFFFF;
// $primary-color: #1495a7;
// $secondary-color: #003687;
// $white: #fff;
// $dark: #110E38;
$gray: #d9d9d9;
$light-gray: #f5f5f5;
$blue-gray: #d5dce3;

// $background-color: #08080A;
$background-color: #0D1117;
// $background-color: #f2f6ff;
$background-color-alt: $white;

$text-color: $dark;

$heading-font-color: $text-color;

$link-color: $text-color;
$link-color-hover: $text-color;

$button-color: $background-color-alt;
$button-background-color: $primary-color;
$button-background-hover: darken($primary-color, 5%);

$border-color: $blue-gray;

$hr-color: $text-color;
$tr-color: #f0f0f0;

$syntax-highlighting-background: #f3f3f3;

$tag-color: #2E2E2E;
$tag-text-color: #909090;