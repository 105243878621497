.bucket-list {
    list-style-type: none;
    padding: 0;
  }
  
  .bucket-list-item {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .bucket-list-item.completed {
    background-color: #478e4d;
  }
  
  .bucket-list-item h3 {
    margin: 0 0 0.5rem 0;
  }
  
  .bucket-list-item p {
    margin: 0 0 0.5rem 0;
  }