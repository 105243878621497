.c-work-card { $c: &;
	margin-bottom: 20px;
	transition: transform .2s ease;

	&:hover {
		transform: translateY(-5px);
	}

	@media only screen and (max-width: $tablet) {
		&:last-child {
			margin-bottom: 0;
		}
	}

	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		min-height: 200px;
		border-radius: 16px;
		padding: 16px;
		background-color: $white;
		transform: translate(0);
		box-shadow: 0px 0px 20px rgba($primary-color, 0.1);
		overflow: hidden;

		@media only screen and (max-width: $desktop) {
			&::after {
				padding-top: 100%;
			}
		}

		@media only screen and (max-width: $mobile) {
			.work__info {
				padding: 0 32px 32px;
			}
		}
	}

	&__header {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
	}

	&__info {
		z-index: 1;
		width: 100%;
		margin-top: auto;
		padding: 0 32px 36px;
		pointer-events: none;
	}

	&__info-wrap {
		overflow: hidden;
	}

	&__logo {
		flex-shrink: 0;
		width: 60px;
		height: 60px;
		margin-right: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $dark;
		border-radius: 8px;
		overflow: hidden;

		img {
			width: 80%;
			height: 80%;
			object-fit: contain;
		}
	}

	&__title {
		flex-grow: 1;
	}

	&__company {
		font-size: 20px;
		font-weight: 700;
		line-height: 1.3;
		margin-top: 4px;
		margin-bottom: 4px;
		color: $text-color;
	}

	&__position {
		font-size: 16px;
		line-height: 1.4;
		color: $text-color;
	}

	&__duration {
		margin-right: 16px;
		font-size: 14px;
	}

	&__location {
		font-size: 14px;
	}

	&__tags {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 16px;
		margin-top: 16px;
	}

	&__tag {
		font-size: 12px;
		line-height: 1;
		padding: 6px 12px;
		border-radius: 16px;
		background-color: $background-color;
		color: $text-color;
		margin-right: 8px;
		margin-bottom: 8px;
	}

	&__description {
		font-size: 14px;
		line-height: 1.6;
		color: $text-color;
	}
}