/* Page Heading */
.c-page-heading {
	max-width: 780px;
	margin: 0 auto;
	padding: 96px 0 60px;
	text-align: center;

	@media only screen and (max-width: $desktop) {
		padding: 80px 0 60px;
	}

	@media only screen and (max-width: $tablet) {
		padding: 40px 0;
	}

	@media only screen and (max-width: $mobile) {
		padding: 30px 0;
	}

	&__title {
		margin: 0;
		font-size: 58px;
		line-height: 1.12;

		@media only screen and (max-width: $tablet) {
			font-size: 36px;
		}

		@media only screen and (max-width: $mobile) {
			font-size: 32px;
		}
	}

	&__description {
		margin: 30px auto 0;
		max-width: 560px;
		line-height: 1.6;

		@media only screen and (max-width: $mobile) {
			margin: 24px auto 0;
		}
	}
}